<template>
  <!-- <div class="card-container" :style="{backgroundImage:'url(' + cardBgImg2 + ')',color:color[bgNumber2 -1]}"> -->
  <div class="card-container" :style="{color:color[bgNumber2 -1]}">
    <div class="header-img">
      <img :src="info.headImg || info.img || 'https://img0.qll-times.com/cdn/zgimg/headimg-default.png'">
    </div>
    <!-- <div class="card-icon">
      <span ><img  :src="iconImg[bgNumber2 -1].companyimg"></span>
      <span ><img  :src="iconImg[bgNumber2 -1].telimg"></span>
      <span ><img  :src="iconImg[bgNumber2 -1].emailimg"></span>
      <span ><img  :src="iconImg[bgNumber2 -1].addressimg"></span>
    </div> -->
    <div class="card-text"  :style="{backgroundColor:cardBgImg2.cardBgImg2.color}">
      <div class="card-name">
        <span class="card-name__name">{{info.name}}</span>
        <span></span>
        <span class="card-name__position">{{info.position || '总裁'}}</span>
      </div>
      
      <p class="card-phonenumber">{{info.tel || info.phoneNumber || '手机号'}}</p>
       <p class="card-company">{{info.companyName || info.companyShortName }}</p>    
      <p class="card-address">{{info.address || '你的地址'}}</p>
      <p class="card-email">{{info.email || '你的邮箱'}}</p>

    </div>

 
  </div> 
</template>

<script>
const colorConfigs = [
  ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'black', 'black', 'black'],
  ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
  ['black', 'black', 'black', 'black', 'black', 'black', 'white', 'white', 'white', 'white'],
]
export default {
  data() {
    return {
      color:[],
      iconImg:[]
    }
  },
  props: ['info','cardBgImg2','cardType2','bgNumber2'],
  created() {
    this.getimage()
    // console.log(this.cardBgImg2.cardBgImg2.color,'this.bgNumber2',this.bgNumber2);
  },
  methods: {
    getimage() {
      for (let i = 0; i < colorConfigs[1].length; i++) {
        this.color.push(colorConfigs[1][i])
      }
      // console.log(this.color);
   
    for (let i = 0; i < 10; i++) {
      this.iconImg.push({
        type:i,
        telimg:`https://img0.qll-times.com/cdn/card/card/style-resource-1/${this.color[i]}-icon-tel.png`,
        companyimg:`https://img0.qll-times.com/cdn/card/card/style-resource-1/${this.color[i]}-icon-company.png`,
        addressimg:`https://img0.qll-times.com/cdn/card/card/style-resource-1/${this.color[i]}-icon-address.png`,
        emailimg:`https://img0.qll-times.com/cdn/card/card/style-resource-1/${this.color[i]}-icon-email.png`,
      })      
    }
    }
  },
}
</script>

<style lang="scss" scoped>
.card-container{
  width:345px;
  height: 206px;
  border-radius: 0.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  overflow: hidden;
  position: relative;
  // background-color: ;
  // background: url('https://img0.qll-times.com/cdn/card/card/card-style-1.png') no-repeat;
  background-color: #CED4E0!important;
  // background: url('https://image.qll-times.com/2021/01/83484c6d86ff4256b69b3a503c1392c0.jpg') no-repeat;
  // background-size: 100%;
  .header-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 206px;
    border-radius: 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
  // .card-icon{
  //   width: 20px;
  //   height: 100%;
  //   float: left;
  //   border-radius: 0.1rem;
  //   font-size: 12px;
  //   font-weight: bold;
  //   position: relative;
  //   z-index: 3;
  //   span{
  //     position: absolute;
  //     width: 20px;
  //     height: 20px;
  //     left: 151px;
  //     // transform: translateX(-50%);
  //     img {
  //       width: 100%;
  //     }
  //   }
  //   span:nth-child(1){
  //     bottom: 83px;
  //   }
  //   span:nth-child(2){
  //     bottom: 60px;
  //   }
  //   span:nth-child(3){
  //     bottom: 37px;
  //   }
  //     span:nth-child(4){
  //     bottom: 15px;
  //   }
  // }
  .card-text{
    float: right;
    width: calc(100% - 150px);
    height: 100%;
    position: relative;
    p{
      position: absolute;
      // color: #fff;
      font-size: 12px;
      margin: 0;
    }
    .card-name{
      position: absolute;
      left: 13px;
      bottom: 84px;
      font-size: 14px;
      text-align: right;
      span {
        &:nth-child(1) {
          font-size: 14px;
        }
         &:nth-child(2) {
          display: inline-block;
          margin: 0 4px;
          width: 1px;
          height: 10px;
          background: #ffffff;;
        }
        &:nth-child(3) {
          font-size: 11px;
        }
      }
    }
    .card-phonenumber{
      bottom: 60px;
      left: 13px;
    }
    .card-company{
      width: 107px;
      height: 34px;
      top: 15px;
      right: 0;
      text-align: center;
      font-size:16px;
      background: rgba(0, 0, 0, .03);
      border-radius: 17px 0 0 17px;
      line-height: 34px;
    }
    .card-email{
      bottom: 25px;
      line-height: 40px;
      left: 13px;
    }
    .card-address{
      bottom: 15px;
      left: 13px;
      width: 160px;
      height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis;
    } 
  }
}
</style>
<template>
  <div class="card-container" :style="{backgroundImage:'url(' + cardBgImg3 + ')',color:color[bgNumber3 -1]}">
    <div class="card-opacity"></div>
    <div class="header-img">
      <img :src="info.headImg || info.img || 'https://img0.qll-times.com/cdn/zgimg/headimg-default.png'">
    </div>
    <div class="card-icon">
      <!-- <span ><img  :src="iconImg[bgNumber3 -1].companyimg"></span> -->
      <span ><img  :src="iconImg[bgNumber3 -1].telimg"></span>
      <span ><img  :src="iconImg[bgNumber3 -1].emailimg"></span>
      <span ><img  :src="iconImg[bgNumber3 -1].addressimg"></span>
    </div>
    <div class="card-text">
      <div class="card-name">
        <span class="card-name__name">{{info.name}}   </span>
        <span class="card-name__position"> / {{info.position || '总裁'}}</span>
      </div>
      
      <p class="card-phonenumber">{{info.tel || info.phoneNumber || '手机号'}}</p>
       <p class="card-company">{{info.companyName || info.companyShortName }}</p>    
      <p class="card-address">{{info.address || '你的地址'}}</p>
      <p class="card-email">{{info.email || '你的邮箱'}}</p>

    </div>

 
  </div> 
</template>

<script>
const colorConfigs = [
  ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'black', 'black', 'black'],
  ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
  ['black', 'black', 'black', 'black', 'black', 'black', 'white', 'white', 'white', 'white'],
]
export default {
  data() {
    return {
      color:[],
      iconImg:[]
    }
  },
  props: ['info','cardBgImg3','cardType3','bgNumber3'],
  created() {
    this.getimage()
    // console.log(this.bgNumber3,'bgNumber3');
  },
  methods: {
    getimage() {
      for (let i = 0; i < colorConfigs[2].length; i++) {
        this.color.push(colorConfigs[2][i])
      }
   
    for (let i = 0; i < 10; i++) {
      this.iconImg.push({
        type:i,
        telimg:`https://img0.qll-times.com/cdn/card/card/style-resource-3/${this.color[i]}-icon-tel.png`,
        companyimg:`https://img0.qll-times.com/cdn/card/card/style-resource-3/${this.color[i]}-icon-company.png`,
        addressimg:`https://img0.qll-times.com/cdn/card/card/style-resource-3/${this.color[i]}-icon-address.png`,
        emailimg:`https://img0.qll-times.com/cdn/card/card/style-resource-3/${this.color[i]}-icon-email.png`,
      })      
    }
    }
  },
}
</script>

<style lang="scss" scoped>
.card-container{
  width:345px;
  height: 206px;
  border-radius: 0.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  overflow: hidden;
  position: relative;
  // background: url('https://img0.qll-times.com/cdn/card/card/card-style-1.png') no-repeat;
  background:  no-repeat;
  // background: url('https://image.qll-times.com/2021/01/83484c6d86ff4256b69b3a503c1392c0.jpg') no-repeat;
  background-size: 100%;
  .card-opacity{
    width: 100%;
    height: 161px;
    position: absolute;
    top: 25px;
    left: 0;
    background-color: rgba(#d8d8d8, 0.4);
  }
  .header-img{
    position: absolute;
    top: 25px;
    right: 10px;
    width: 102px;
    height: 161px;
    // border-radius: 0 8px 8px 0;
    img{
      width: 100%;
      height: 100%;
    // border-radius: 0 8px 8px 0;

    }
  }
  .card-icon{
    width: 20px;
    height: 100%;
    float: left;
    border-radius: 0.1rem;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    z-index: 3;
    span{
      position: absolute;
      width: 14px;
      height: 14px;
      left:18px;
      // transform: translateX(-50%);
      img {
        width: 100%;
      }
    }
    span:nth-child(1){
      bottom: 78px;
    }
    span:nth-child(2){
      bottom: 59px;
    }
    span:nth-child(3){
      bottom: 38px;
    }
    //   span:nth-child(4){
    //   bottom: 34px;
    // }
  }
  .card-text{
    width: 100%;
    height: 100%;
    position: relative;
    p{
      position: absolute;
      font-size : 12px;
      left: 37px;
    }
    .card-name{
      position: absolute;
      left: 19px;
      top: 88px;
      font-size: 14px;
      text-align: right;
      span {
        &:nth-child(1) {
          font-size: 14px;
        }
        &:nth-child(2) {
          font-size: 11px;
          padding-left: 3px;
        }
      }
     
    }
    .card-phonenumber{
      bottom: 76px;
    }
    .card-company{
      top: 39px;
      left: 16px;
      font-size: 13px;
    }
    .card-email{
      bottom: 59px;
    }
    .card-address{
      bottom: 39px;
      width: 160px;
      height: 16px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    } 
  }
}
</style>
<template>
  <div class="card-container">
    <div class="header-bg">
      <!-- 名片 -->
      <card-one v-if="configs.cardType == 1" 
        :info="configs.info"
        :cardBgImg1= "cardBgImg.cardBgImg1"
        :company="configs.company.info.shortName"
        :cardType1="cardType"
        :bgNumber1="bgNumber"></card-one>
      <card-two v-else-if="configs.cardType == 2" 
      :info="configs.info"
      :company='configs.company' 
      :cardBgImg2= "cardBgImg"
       :cardType2="cardType"
      :bgNumber2="bgNumber" ></card-two>
      <card-three v-else-if="configs.cardType == 3" 
      :info="configs.info" 
      :cardBgImg3= "cardBgImg.cardBgImg3"
      :cardType3="cardType"
      :bgNumber3="bgNumber" ></card-three>

        <!-- 浏览人头像，点赞,电话、私信 -->
      <div class="card-code-test">
          <div class="card-item-test">
            <img src="https://image.qll-times.com/2021/04/43f87971d38d41b39100cafc4cafe3cf.png" alt="">
            58
          </div>
          <div class="card-item-test">
            <img src="https://image.qll-times.com/2021/04/bb14a71be49b4ce2a0df718300435478.png" alt="">
            12
          </div>
          <div class="card-item-test">
            <img src="https://image.qll-times.com/2021/04/a0a05e4559154e8ea71c89757e26373d.png" alt="">
            287
          </div>
          <div class="card-item-test card-btns">
            <div class="card-item-btn"><img src="https://image.qll-times.com/2021/04/832d3b3404764999b203cea97acec3f9.png" alt="私信"> 私信</div>
            <div class="card-item-btn"><img src="https://image.qll-times.com/2021/04/f00b21be056448b88c4d92aabaa22e74.png" alt="致电">致电</div>
          </div>
      </div>

    </div>
     
    
      <!-- 分享、保存入通讯录 -->
    <div class="share">
      <el-button @click="sendEmit('cardShare')" class="share-button">分享名片</el-button>
      <el-button  @click="exchangePhone()" type="primary"  class="share-exchange">存入通讯录</el-button>
    </div>


    <div class="content-bg">
       <!-- 个人简介 -->
      <!-- <div class="brief">
        <div class="item-header">
          <h2>个人简介</h2>
        </div>
        <div class="item-content">
          <p v-if="configs.introduce.content" class="info">{{ configs.info.des }}</p>
          <p v-else class="no-info">暂无个人简介</p>
        </div>
      </div> -->

      <!-- 我的公司 -->
      <div class="brief">
        <div class="item-header">
          <h2>我的公司</h2>
        </div>
        <div class="item-content">
          <div v-if="configs.introduce.content">
             <p  class="info">{{ configs.introduce.content }}</p>
             <van-tag  class="tags-list" size="medium" color="#6e87bd"
            v-for="(item, index) in configs.introduce.tags"
            :key="index">{{item}}</van-tag>
          </div>
         
           <p v-else class="no-info">暂无公司简介</p>
           
        </div>
      </div>


        <!-- 公司官网 -->
      <div class="brief">
        <div class="item-header">
          <h2>公司官网</h2>
        </div>
        <div class="company">
          <div class="website-header">
            <div class="company-log"><img :src="this.configs.company.info.logo || `https://img0.qll-times.com/cdn/zgimg/headimg-default.png`" alt=""></div>
            <div class="company-name" >
              <span v-if="configs.info.companyName">{{configs.info.companyName}}</span>
              <span v-else>暂无公司信息</span>
              <span v-if="configs.info.address">{{configs.info.address}}</span>
              <span v-else>暂无公司地址信息</span>
            </div>
            <div class="company-website">
              查看<br>官网
            </div>
          </div>
        </div>
      </div>

      <!-- 公司风采 -->
      <div class="brief">
        <div class="item-header">
          <h2>公司风采</h2>
        </div>
        <div class="item-content image" v-if="configs.image.images">
          <img  v-for="(item, index) in configs.image.images" :key="index" :src="item">
        </div>
        <div class="item-content image" v-else>
          <p class="no-info">暂无公司图片</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import cardOne from './CardTemplate1'
import cardTwo from './CardTemplate2'
import cardThree from './CardTemplate3'
import {Tag} from 'vant'
Vue.use(Tag)
export default {
  props: ['configs', 'editmodel','cardBgImg','cardType','bgNumber'],
  data(){
    return{
      companyLogo: '',
    }
  },
  mounted() { 
      this.companyLogo =  this.configs.company.info.logo ;
      console.log(this.configs);
  },
    
  methods: {
    // 交换手机号
    async exchangePhone(){
      if(this.editmodel) return;
      this.$emit("cardExchangePhone");
    },

    // 跳转到企业官网
    redirectWebSite(){
      if(this.editmodel) return;
      let url = this.$route.params
      this.$router.replace(`/site/${url.cid}/${url.tid}/${url.rt}/${url.rid}/${url.sid}/${url.uid}`);
    },

    // 复制功能
    copyData(id, data) {
      if(this.editmodel) return;
      this.prizeShow = false;
      this.get_suc = true
      const range = document.createRange();
      range.selectNode(document.getElementById(id));
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      this.$toast("已复制到您的剪贴板");
    },

    // 发出事件
    sendEmit(val){
      console.log(val);
      this.$emit(val);
    }
  },
  components: {
    'card-one': cardOne,
    'card-two': cardTwo,
    'card-three': cardThree
  }
}
</script>

<style lang="scss" scoped>
.no-info {
  text-align: center;
  color:#999999;

}
.card-container{
  background-color: #f9f9f9;
  font-family: Helvetica;
}
.header-bg{
  padding: 0.25rem;
  background-color: #fff;
}
.content-bg{
  // // padding: 0.25rem;
  // padding-left: 15px;
  padding: 0.3rem;
}
.item-header{
  overflow: hidden;
  img{
    width: 0.48rem;
    height: 0.48rem;
    float: left;
  }
  h2{
    font-size: 15px;
    font-weight: bold;
    float: left;
    margin: 0;
  }
}

.company {
  color: #111111;
  background-color: #fff;
  border-radius: 0.1rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
   .website-header{
    font-size: 0.3rem;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0.2rem;
    display: flex;
    .company-log {
      height: 60px;
      img {
        height: 100%;
        border-radius: 5px;
      }
    }
    .company-name {
      padding-left: 5px;
      flex: 1;
      display: flex;
      font-size: 12px;
      -webkit-transform: scale(1.0); 
      flex-direction:column;
      justify-content: center;
      span {
       line-height: 20px;
       &:nth-child(2) {
         color: #999999;
         height: 20px;
         overflow: hidden;
       }
      }
    }
    .company-website {
      padding-top: 12px;
      width: 60px;
      font-size: 12px;
      background-color: #0091FF;
      border-radius: 0 5px 5px 0;
      color: #ffffff;
      text-align: center;
      line-height: 18px;
    }
  }
}
.brief {
  margin-top: 10px;
  margin-bottom: 20px;
}
.item-content{
  color: #999999;
  font-size: 12px;
  margin-top: 0.25rem;
  .info{
    font-size: 0.26rem;
    line-height: 0.48rem;
  }
  .address{
    font-size: 0.3rem;
    position: relative;
    .address-button{
      position: absolute;
      top: 0.3rem;
      right: 0;
      display: inline-block;
      border: 0.01rem solid #cccccc;
      border-radius: 0.08rem;
      padding: 0 0.25rem;
    }
  }
  .tags-list{
    margin-right: 0.25rem;
    margin-top: 0.25rem;
  }
  .website-header{
    font-size: 0.3rem;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0.2rem;
    img{
      width: 1rem;
      height: 1rem;
      float: left;
    }
    .website-title{
      line-height: 1rem;
      padding-left: 0.25rem;
    }
    .website-button{
      color: #1a9afd;
      border: 0.02rem solid #1a9afd;
      border-radius: 0.1rem;
      line-height: 0.4rem;
      padding: 0.05rem 0.2rem;
      float: right;
      font-size: 0.24rem;
      margin-top: 0.25rem;
    }
  }
  .website-content{
    pre{
      margin-bottom: 0;
      white-space: pre-line;
      font-size: 0.26rem;
      line-height: 0.48rem;
    }
  }
  hr{
    border: none;
    height: 0.01rem;
    background-color: #d8d8d8;
    margin: 0.25rem 0;
  }
}
.image{
  text-align: center;
  img{
    display: inline-block;
    max-width: 100%;
    width: 100%;
    // height: 190px;
    border-radius: 5px;
  }
}
.browse{
  margin-top: 0.5rem;
  span{
    display: inline-block;
    height: 0.5rem;
    line-height: 0.5rem;
    vertical-align: middle;
    font-size: 0.28rem;
    color: #999999;
  }
  .header-img{
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .yxzs_icon{
    float: right;
  }
}
.share{
  width: calc(100% - 0.6rem);
  overflow: hidden;
  margin-top: 0.4rem;
  height: 1rem;
  padding: 0 0.3rem;
  .share-button{
    border: 1px solid  #1a9afd;
    color:  #1a9afd;
    width: 47%;
    // line-height: 0.8rem;
    // border-radius: 0.4rem;
    text-align: center;
    // font-size: 0.34rem;
    margin-left: 1%;
    background-color: #fff;
    float: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
  }
  .share-exchange {
    border: 1px solid  #1a9afd;
    width: 47%;
    float: right;
    margin-right: 1%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
  }

}
.card-code{
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 0.35rem;
  .code{
    font-size: 0.24rem;
    .code-text{
      display: inline-block;
      width: 0.3rem;
      line-height: 0.24rem;
      text-align: center;
      border: 0.01rem solid #999999;
      padding: 0.1rem;
    }
  }

  .card-info{
    width: max-content;
    span{
      color: #1a9afd;
      font-size: 0.3rem;
    }
    .card-item{
      display: inline-block;
      font-size: 0.24rem;
      color: #333333;
      line-height: 0.48rem;
      border: 0.5px solid #bbbbbb;
      padding: 0 0.25rem;
      border-radius: 0.1rem;
      p{
        line-height: 0.24rem;
        font-size: 0.24rem;
        margin: 0.24rem 0;
      }
      a{
        color: #333333;
        text-decoration: none;
      }
    }
  }
}

.card-code-test {
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
    .card-btns {
      width: 120px;
      display: flex;
    }
    .card-item-test{
      height: 30px;
      // display: flex;
      font-size: 12px;
      color: #333333;
      line-height: 30px;
      padding: 0 0.25rem;
      border-radius: 0.1rem;
      img {
        display: inline-block;
      }
      .card-item-btn {
        font-size: 10px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        width: 60px;
        height: 100%;
        &:nth-child(1) {
          background-color: #32C5FF;
          border-radius: 5px 0 0 5px;
        }
        &:nth-child(2) {
          background-color: #0091FF;
           border-radius: 0 5px 5px 0;
        }
        img {
          vertical-align: middle;
        }
      }
  }
}


.info-img{
  position: fixed;
  right: 0.1rem;
  bottom: 3rem;
  width: 1rem;
  z-index: 9;
  img{
    width: 1rem;
    height: 1rem;
    border: 0.04rem solid #ffffff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }
  span{
    display: inline-block;
    font-size: 0.24rem;
    line-height: 0.4rem;
    color: #111111;
    width: 1rem;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 0.24rem;
    border-top-right-radius: 0.24rem;
    border-bottom-left-radius: 0.24rem;
    border-bottom-right-radius: 0.24rem;
    position: absolute;
    top: 0.9rem;
  }
}
</style>
<template>
  <div class="card-container" :style="{backgroundImage:'url(' + cardBgImg1 + ')',color:color[bgNumber1 -1]}">
    <div class="header-img">
      <img :src="info.headImg || info.img || 'https://img0.qll-times.com/cdn/zgimg/headimg-default.png'">
    </div>
    <div class="card-icon">
      <span ><img  :src="iconImg[bgNumber1 -1].companyimg"></span>
      <span ><img  :src="iconImg[bgNumber1 -1].telimg"></span>
      <span ><img  :src="iconImg[bgNumber1 -1].emailimg"></span>
      <span ><img  :src="iconImg[bgNumber1 -1].addressimg"></span>
    </div>
    <div class="card-text">
      <div class="card-name">
        <span class="card-name__name">{{info.name || '公司名称'}}</span>
        <span class="card-name__position">{{info.position || '总裁'}}</span>
      </div>
      <p class="card-phonenumber">{{info.tel || info.phoneNumber || '手机号'}}</p>
       <p class="card-company">{{info.companyName || info.companyShortName }}</p>    
      <p class="card-address">{{info.address || '你的地址'}}</p>
      <p class="card-email">{{info.email || '你的邮箱'}}</p>

    </div>

 
  </div> 
</template>

<script>
import {cardResource} from '@/js/Utils'
const colorConfigs = [
  ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'black', 'black', 'black'],
  ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
  ['black', 'black', 'black', 'black', 'black', 'black', 'white', 'white', 'white', 'white'],
]
export default {
  data() {
    return {
      color:[],
      iconImg:[]
    }
  },
  props: ['info','cardBgImg1','cardType1','bgNumber1'],
  created() {
    this.getimage()
  },
  methods: {
    getimage() {
    console.log(this.info);
      for (let i = 0; i < colorConfigs[0].length; i++) {
        this.color.push(colorConfigs[0][i])
      } 
    for (let i = 0; i < 10; i++) {
      this.iconImg.push({
        type:i,
        telimg:`https://img0.qll-times.com/cdn/card/card/style-resource-${this.cardType1}/${this.color[i]}-icon-tel.png`,
        companyimg:`https://img0.qll-times.com/cdn/card/card/style-resource-${this.cardType1}/${this.color[i]}-icon-company.png`,
        addressimg:`https://img0.qll-times.com/cdn/card/card/style-resource-${this.cardType1}/${this.color[i]}-icon-address.png`,
        emailimg:`https://img0.qll-times.com/cdn/card/card/style-resource-${this.cardType1}/${this.color[i]}-icon-email.png`,
      })      
    }
    }
  },
}
</script>

<style lang="scss" scoped>
.card-container{
  width:345px;
  height: 206px;
  border-radius: 0.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  overflow: hidden;
  position: relative;
  // background: url('https://img0.qll-times.com/cdn/card/card/card-style-1.png') no-repeat;
  background:  no-repeat;
  // background: url('https://image.qll-times.com/2021/01/83484c6d86ff4256b69b3a503c1392c0.jpg') no-repeat;
  background-size: 100%;
  .header-img{
    float: right;
    margin-top: 0.38rem;
    margin-right: 0.47rem;
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .card-icon{
    width: 20px;
    height: 100%;
    float: left;
    border-radius: 0.1rem;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    z-index: 3;
    span{
      position: absolute;
      width: 20px;
      height: 20px;
      left: 16px;
      // transform: translateX(-50%);
      img {
        width: 100%;
      }
    }
    span:nth-child(1){
      bottom: 89px;
    }
    span:nth-child(2){
      bottom: 64px;
    }
    span:nth-child(3){
      bottom: 39px;
    }
      span:nth-child(4){
      bottom: 14px;
    }
  }
  .card-text{
    width: 100%;
    height: 100%;
    position: relative;
    p{
      position: absolute;
      font-size : 12px;
      left: 46px;
    }
    .card-name{
      position: absolute;
      right: 75px;
      top: 25px;
      text-align: right;
      display: flex;
      flex-direction: column;
      span {
        &:nth-child(1) {
          font-size: 14px;
        }
        &:nth-child(2) {
          font-size: 12px;
        }
      }
     
    }
    .card-phonenumber{    
      bottom: 67px;
    }
    .card-company{
      bottom: 92px;
    }
    .card-email{
      bottom: 42px;
    }
    .card-address{
      bottom: 19px;
      width: 188px;
      height: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis;
    } 
  }
}
</style>
<template>
  <div style="absolute">
    <div class="scroll-none-custom left">
      <card-template v-if="configs && configs.cardType" 
        :configs="configs" 
        :cardBgImg="cardBgImg"
        :editmodel="true"
        :cardType="configs.cardType"
        :bgNumber="configs.bgNumber"></card-template>
    </div>
    <div class="right">
      <el-alert :closable="false"
        title="默认内容：企业可编辑一套名片页默认内容，快速对员工名片页初始化装修。
        预览以商机圈子提供的默认名片为例，仅作示意；模块名称设置后，将在您企业名下的所有名片页生效，请谨慎操作。">
      </el-alert>
      <div class="title">编辑名片默认内容
        <el-button @click="updateCardConfigs()" class="save-button" type="primary">保存</el-button>
      </div>
      <el-collapse v-if="configs && configs.cardType" v-model="collapseActiveName">
        <!-- 名片样式 -->
        <el-collapse-item name="1" title="名片样式">
          <!-- 名片样式 -->
          <div class="margin-em" style="height: 72px">
            <div class="card-type" :class="{'card-type-actived': item.type == configs.cardType}"
              v-for="(item, index) in cardTypeList" :key="index"
              @click="selectCardStyle(item)">
              <img :src="item.img" >
            </div>
          </div>

          <!-- 名片背景 -->
          <h3 class="slides-box-title">名片背景</h3>
          <div class="slides-box">
            <ul class="slider" v-if="this.configs.cardType === 1">
              <li class="slider-item" :class="{'bacardBg': index ===configs.bgNumber-1 }"
                v-for="(item,index) in cardBgList" :key="index" @click="editCardBg(item,index)">
                <img :src="item.img">
              </li>
            </ul>
            <ul class="slider" v-if="this.configs.cardType === 2">
              <li class="slider-item" :class="{'bacardBg': index ===configs.bgNumber }"
                v-for="(item,index) in cardBgColorList" :key="index" @click="editCardBg(item,index)">
                <div :style="{backgroundColor:item.color}"></div>
              </li>
            </ul>
            <ul class="slider" v-else-if="this.configs.cardType == 3">
              <li class="slider-item" :class="{'bacardBg': index ===configs.bgNumber-1 }"
                v-for="(item,index) in cardBgList" :key="index" @click="editCardBg(item,index)">
                <img :src="item.img">
              </li>
            </ul>  
          </div>
        </el-collapse-item>

        <!-- 名片信息 -->
        <el-collapse-item name="2" title="名片信息">
          <div class="card-info">
            <label>姓名：</label>
            <span>{{configs.info.name}}</span>
          </div>
          <div class="card-info">
            <label>手机：</label>
            <span>{{configs.info.tel}}</span>
          </div>
          <div class="card-info">
            <label>职位：</label>
            <span>{{configs.info.position}}</span>
          </div>
          <div class="card-info">
            <label>邮箱：</label>
            <span>{{configs.info.email}}</span>
          </div>
         <div class="card-info">
            <label>公司：</label>
            <span>{{configs.info.companyName}}</span>
          </div>
          <div class="card-info">
            <label>地址：</label>
            <el-input class="address"
              placeholder="请输入地址或点击地址选择按钮选择地址"
              v-model="configs.info.address"
              clearable>
            </el-input>
          </div>
        </el-collapse-item>

        <!-- 我的公司 -->
        <el-collapse-item name="3" title="我的公司">
          <el-input class="margin-em brief"
            type="textarea"
            :rows="6"
            maxlength="500"
            show-word-limit
            placeholder="请输入个人简介"
            v-model="configs.introduce.content">
          </el-input>
          <div class="margin-em brief">
            <label>企业标签：</label>
            <span>企业标签将显示在您企业名下所有员工的名片中，最多8个标签。</span>
          </div>
          <div class="margin-em brief">
            <!-- 展示标签 -->
            <el-tag class="show-tag"
              v-for="(item, index) in configs.introduce.tags" :key="index"
              closable
              :disable-transitions="false"
              @close="delCompanyTags(item)">
              {{item}}
            </el-tag>

            <!-- 添加标签输入框 -->
            <el-input maxLength="10"
              class="input-new-tag"
              v-if="companyTags.enabled"
              v-model="companyTags.value"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="inputCompanyTags"
              @blur="inputCompanyTags">
            </el-input>

            <!-- 添加标签 -->
            <el-button v-if="!companyTags.enabled && configs.introduce.tags.length < 8"
              class="button-new-tag"
              icon="el-icon-plus"
              size="small"
              @click="addCompanyTags">添加标签
            </el-button>
          </div>
        </el-collapse-item>

        <!-- 公司照片 -->
        <el-collapse-item name="5" title="公司风采">
          <div class="margin-em brief">图片大小不超过2M，支持jpg、png格式，最多可添加9张图片</div>
          <div class="margin-em brief">
            <div v-for="(item, index) in configs.image.images" :key="index" class="image-item">
              <img :src="item">
              <div class="image-handel">
                <span class="el-icon-view"></span>
                <span @click="delUploadimage(index)" class="el-icon-delete"></span>
              </div>
            </div>
            <el-upload v-if="this.configs.image.images.length < 9"
              class="image-uploader"
              action="/api/upload/file"
              :show-file-list="false"
              :on-success="imageUploadSuccess"
              :before-upload="imageUploadLimit">
              <i class="el-icon-plus image-uploader-icon"></i>
            </el-upload>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import cardTemplate from "@/websites/cms/components/card/CardTemplate";
const bgColor= ['#CED4E0','#0091FF','#44D7B6','#6236FF','#32C5FF','#6DD400','#F7B500','#FA6400','#E02020','#333333'];
export default {
  data(){
    return {
      collapseActiveName: "1", // 名片编辑时当前展开的collapse的name
      cardTypeList: this.$configs.cardThum,
      cardBgList: [],//名片样式为1和3时候的背景侧滑图片
      cardBgColorList:[],//名片样式为2时候的背景颜色图
      companyCard: { // 公司名片模板ID
        id: 0,
        name: "",
      }, 
      configs: { // 名片模板配置信息
        cardType: 3, // 当前选择的名片类型
        bgNumber:'1',//当前选中的背景类型
        info: { // 名片信息
          name: '全流量',
          position: '全流量合伙人',
          tel: "13390513333",
          email: "tech@qll-times.com",
          img: 'https://image.qll-times.com/2020/05/4156f291c0f34180b07851311e8552ad.jpeg',
          wxChat: 'TEST-QLLSD',
          address: '北京市海淀区唐家岭路百旺宏祥1989科技文化产业园8号楼H8216',
          companyName: '',
          companyShortName: '',
          des:'',
        },
        visit: { // 名片的被访问信息
          recentAccessData:[this.$configs.headImg], // 访问者头像数组
          accessTotal: 0, // 访问人数
          likeTotal: 0, // 点赞人数
          iLike: 0, // 我是否点赞 0 否 1 是 
          phoneExchange: 0, // 号码交换 0 否 1 是
        },
        introduce: { // 个人简介
          enabled: true,
          name: '个人简介',
          // content: '全流量时代（北京）信息技术有限公司是专业的云权益营销服务商。以权益云为平台，通过云计算，将复杂的底层信息资源转化为建议的API或SDK接口，为企业客户提供SAAS云软件即服务，让企业的互动营销能力“零门槛”、低资费、高效率、轻松按需使用。',
          content: '',
          tags: ['优秀企业', '精神小伙'],
        },
        company: { // 我的公司
          enabled: true,
          name: '我的企业',
          info: { // 公司信息
            logo: '',
            shortName: '',
            content: '',
            // content: '公司简介：全流量时代（北京）信息技术有限公司是专业的云权益营销服务商。以权益云为平台，通过云计算，将复杂的底层信息资源转化为建议的API或SDK接口，为企业客户提供SAAS云软件即服务，让企业的互动营销能力“零门槛”、低资费、高效率、轻松按需使用。',
            tags:  ['优秀企业', '男'],
          },
        },
        image: { // 我的照片
          enabled: true,
          name: '我的照片',
          images: [this.$configs.headImg]
        },
      },
      companyTags: { // 编辑模式下企业标签
        enabled: false,
        value: ''
      },
      cardBgImg: { //名片背景图片
        cardBgImg1: 'https://img0.qll-times.com/cdn/card/card/style-resource-1/bg1.png',
        cardBgImg2: '#0091FF',
        cardBgImg3: 'https://img0.qll-times.com/cdn/card/card/style-resource-3/bg1.png',
      },
    }
  },
  created(){
    this.getMyInfo();
    this.getMyCompany();
    this.getCardConfigs();
    // console.log(this.configs.bgNumber,this.configs.cardType);
    for (let i = 1; i < 11; i++) {
      this.cardBgList.push({
        type:i,
        img:`https://img0.qll-times.com/cdn/card/card/style-resource-${this.configs.cardType }/bg${i}.png`,
      }) 
    }
  },
  methods: {
    //选择名片样式，展示第几个样式
    selectCardStyle(item) {
      this.configs.cardType = item.type;
      if (this.configs.cardType === 1) {
        this.cardBgList= [];
        for (let i = 1; i < 11; i++) {
          this.cardBgList.push({
            type:i,
            img:`https://img0.qll-times.com/cdn/card/card/style-resource-${this.configs.cardType }/bg${i}.png`,
          }) 
        }
        }
      else if(this.configs.cardType === 3){
        this.cardBgList= [];
         for (let i = 1; i < 11; i++) {
          this.cardBgList.push({
            type:i,
            img:`https://img0.qll-times.com/cdn/card/card/style-resource-${this.configs.cardType }/bg${i}.png`,
          }) 
        }
      }else {
        this.cardBgColorList = [];
        for (let i = 0; i < bgColor.length; i++) {
          this.cardBgColorList.push( {
            type:i,
            color:bgColor[i]
          })
        }
        console.log('this.cardBgColorList',this.cardBgColorList);
      }
    },
    //修改名片背景
    editCardBg(item,index) {
      // console.log('滑动列表item===',item);

      switch (this.configs.cardType) {
        case  1:
      this.cardBgImg.cardBgImg1 = item.img;
      this.configs.bgNumber = item.type;
        break;
        case  2:
      this.cardBgImg.cardBgImg2 = item;
      this.configs.bgNumber = item.type;
      // console.log(this.configs.bgNumber);
      // console.log(this.cardBgImg.cardBgImg2);
          break;
        case  3:
      this.cardBgImg.cardBgImg3 = item.img;
      this.configs.bgNumber = item.type;
          break;
        default:this.cardBgImg.cardBgImg1 = item.img;
        this.configs.bgNumber = 1;    
          break;
      }
      this.configs.bgNumber = item.type;
    },
    //获取个人信息
    async getMyInfo() {
      let res = await this.$ZGManager.getMyInfo();
      // console.log('个人信息===',res);
      if(res.status == 200) {
        this.configs.info.name = res.data.name;
        this.configs.info.position = res.data.position;
        this.configs.info.tel = res.data.phoneNumber;
        this.configs.info.email = res.data.email;
        this.configs.info.address = res.data.address;
        this.configs.info.des = res.data.intro;
        this.configs.info.companyName = res.data.companyName || res.data.companyShortName
      }
    }
    ,
    // 查询企业信息，并增加到模板配置中去
    async getMyCompany(){
      let res = await this.$ZGManager.getMyCompany();
      console.log(res,'公司信息');
      this.configs.company.info.log = res.data.logo;
      this.configs.company.info.shortName = res.data.name;
      if(res.status == 200){
        this.configs.introduce.content = res.data.description;
      }
      // console.log('企业信息---',res);
    },

    // 获取名片装修配置
    async getCardConfigs(){
      let res = await this.$ZGManager.getcmstmzConfigs(1);
      if(res.status == 200 && res.data){
        this.companyCard.id = res.data.id;
        this.companyCard.name = res.data.name;
        let data = JSON.parse(res.data.configs);
        console.log(data,'data');
        this.configs = Object.assign(this.configs, data);
        // this.getMyCompany();
      }
    },

    // 创建/更新名片装修配置
    async updateCardConfigs(){
      let dialog = await this.$confirm(`保存后会立即生效，是否确定保存`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      let res;

      // 保存企业模板配置之前，需要将模板配置中的info删除
      let data = JSON.parse(JSON.stringify(this.configs));
      console.log('data',data);
      delete data.info;

      // companyCard 大于 0 是保存配置，其余则为创建配置
      if(this.companyCard.id > 0){
        let params = {
          name: this.companyCard.name,
          configs: JSON.stringify(data)
        }
        res = await this.$ZGManager.updateCardConfigs(this.companyCard.id, params);
        if(res.status == 200 && res.data == "成功"){
          this.$message.success("保存成功");
        }
      }else{
        res = await this.$ZGManager.createCardConfigs("系统默认名片", "", JSON.stringify(data));
        if(res.status == 200 && res.data == "成功"){
          this.$message.success("保存成功");
        }
      }
    },

    // 删除企业标签
    delCompanyTags(item) {
      this.configs.introduce.tags.splice(this.configs.introduce.tags.indexOf(item), 1);
    },

    // 添加企业标签
    addCompanyTags(){
      if(this.configs.introduce.tags.length >= 8){
        return this.$message.error('标签数量不能超过8个');
      }
      this.companyTags.enabled = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    // 输入企业标签
    inputCompanyTags() {
      if (this.companyTags.value) {
        if(this.companyTags.value.length > 10){
          return this.$message.error('标签内容不能超过10个字符')
        }
        this.configs.introduce.tags.push(this.companyTags.value);
      }
      this.companyTags.enabled = false;
      this.companyTags.value = '';
    },
    
    // 限制图片上传
    imageUploadLimit(file) {
      if(this.configs.image.images.length >= 9){
        this.$message.error("最多可添加9张图片");
        return false;
      }
      const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error('上传的图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传的图片大小不能超过 2MB!');
      }
      return isImage && isLt2M;
    },

    // 图片上传成功回调
    imageUploadSuccess(res){
      if(res.status == 0 && res.data.url){
        this.configs.image.images.push(res.data.url);
      }else{
        this.$message.error(res.message);
      }
    },

    // 删除上传的图片
    delUploadimage(index){
      if(this.configs.image.images.length <= 1){
        return this.$message.error("必须保留一张图片");
      }
      this.configs.image.images.splice(index, 1);
    }
  },
  components: {
    'card-template': cardTemplate,
  }
}
</script>

<style lang="scss" scoped>
.left{
  width: 375px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  position: relative;
}
.right{
  width: calc(100% - 405px);
  height: calc(100% - 20px);
  float: right;
  background-color: #fff;
  padding: 10px;
  margin-left: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
  .title{
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 12px;
    position: relative;
   
    .save-button{
      float: right;
    }
  }
  .card-type{
    display: inline-block;
    width: 100px;
    height: 60px;
    margin-right: 20px;
    background-color: transparent;
    img{
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .card-type-actived{
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
    transform: scale(1.2);
    transform-origin: 50% 50%;
  }
  .card-info{
    font-size: 14px;
    line-height: 38px;
    padding-left: 2em;
    label{
      color: #111111;
      width: 60px;
      display: inline-block;
    }
    span{
      color: rgb(79, 79, 79);
    }
    .address{
      display: inline-block;
      width: 400px;
    }
  }
  .brief{
    margin-top: 10px;
    span{
      color: #888888;
    }
    .input-new-tag{
      display: inline-block;
      width: 150px;
    }
    .show-tag{
      margin-right: 10px;
    }
    label{
      display: inline-block;
      width: 70px;
    }
  }
  .liner-input{
    display: inline-block;
    width: calc(100% - 75px);
  }
  .image-item{
    width: 104px;
    height: 104px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    position: relative;
    float: left;
    margin: 0 10px 10px 0;
    img{
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover{
      .image-handel{
        display: block;
      }
    }
    .image-handel{
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.7);
      position: relative;
      z-index: 8;
      display: none;
      span{
        display: inline-block;
        width: 49px;
        height: 104px;
        line-height: 104px;
        color: #ffffff;
        font-size: 24px;
        text-align: center;
      }
    }
  }
  .image-uploader{
    width: 104px;
    height: 104px;
    float: left;
    position: relative;
    margin-bottom: 10px;
    ::v-deep .el-upload{
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    ::v-deep .el-upload:hover {
      border-color: #409EFF;
    }
    .image-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 104px;
      height: 104px;
      line-height: 104px;
      text-align: center;
    }
    .uploader-image {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.margin-em{
  width: calc(100% - 2em);
  margin-left: 2em;
  display: flex;
  align-items: center;
}
.slides-box {
  width: 100%;
  height: 70px;
  .slider {
    margin-left: 20px;
    width: 100%;
    .slider-item {
      cursor: pointer;
      float: left;
      padding: 10px;
      width: 100px;
      height: 60px;
      img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      margin-bottom: 10px;
      }
      div {
        float: left;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
}
.slides-box-title{
  height: 48px;
  line-height: 48px;
  color: #303133;
  font-size: 13px;
  font-weight: 500;
  outline: 0;
}
.bacardBg {
  transform: scale(1.2);
  transform-origin: 50% 50%;
  background-color: transparent;
}
</style>
